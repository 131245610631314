<template>
  <div id="signDetail">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="projectName"
            left-text
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div style="padding: 16px 12px;">
          <div class="boxstyle">
            <div class="paymonry">
              {{ $t('signLogin.partTitle') }}
              <span class="changId" @click="changeId">{{ $t('signLogin.changeUser') }}</span>
            </div>
            <van-cell>
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <span class="custom-title">{{ $t('main.name') }}</span>
              </template>
              <span>{{ registration.name }}</span>
            </van-cell>
            <van-cell>
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <span class="custom-title">{{ $t('main.certificateNumber') }}</span>
              </template>
              <span>{{ registration.idserial }}</span>
            </van-cell>
            <div class="paymonry">{{ $t('commonPay.title1') }}</div>
            <van-form :model="diyTemp" style="margin: -2px 5px;" @submit="onSubmit">
              <van-row v-for="item in registration.diyFormList" :key="item.key" style="border-bottom: 0.02667rem solid #ebedf0;">
                <van-col>
                  <van-field v-if="item.type==='input'" v-model="diyTemp[item.key]" :required="item.pros== null? false:true" :placeholder="item.placeholder" :readonly="item.readonly" :name="item.comName" :label="item.label" :rules="item.pros== null? [{ required: false }] :[{ required: true, message: $t('msg.noNull') }]" />
                  <van-field v-if="item.type==='switch'" :name="item.comName" :required="item.pros== null? false:true" :label="item.label" :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: '必填项不能为空' }]">
                    <template #input>
                      <van-radio-group v-if="item.type==='switch'" v-model="diyTemp[item.key]" direction="horizontal">
                        <van-radio v-for="o in statusOptions" :key="o.key" :name="o.key">{{ o.value }}</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <van-field
                    v-else-if="item.type==='select'"
                    readonly
                    clickable
                    :label="item.label"
                    :value="diyTemp[item.key]"
                    :placeholder="item.placeholder"
                    :required="item.pros== null? false:true"
                    :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: '必填项不能为空' }]"
                    @click="pickerClick(item)"
                  />
                  <van-popup v-model="item.showPicker" round position="bottom">
                    <van-picker
                      show-toolbar
                      :columns="item.optionsDate"
                      @cancel="item.showPicker = false"
                      @confirm="onConfirm"
                    />
                  </van-popup>
                </van-col>
              </van-row>
              <div class="bottom">
                <van-button type="info" size="large" class="bottombutton" native-type="submit" :loading="btnNextLoading">{{ $t('main.next') }}</van-button>
              </div>
              <div class="bottom">
                <van-button
                  type="info"
                  size="large"
                  class="bottombutton"
                  native-type="submit"
                >{{ $t('main.next') }}</van-button>
              </div>
            </van-form>
            <div class="paymonry">{{ $t('pay.payment-amount') }}</div>
            <!-- <div class="lastbattry">剩余电量:<span>--</span></div> -->
            <van-cell style="border-bottom: 0.02667rem solid #ebedf0;">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <span class="custom-title">{{ $t('main.money') }}</span>
              </template>
              <span class="red">￥{{ fenToYuan(temp.payamt) }}</span>
            </van-cell>
          </div>

        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
// import { getPayCodeListByProId } from '@/api/waitingPay'
import { getUnpaidOrder, getOrderInfoByNameListId, createRegistrationOrder } from '@/api/registration/projectForRegistration'
import { fenToYuan } from '@/utils/commonUtils'
import { getProjectVoById } from '@/api/projectApply'
import { getPayCodeListByProId } from '@/api/waitingPay'
// import store from '@/store'
// import { Dialog, Toast } from 'vant'
export default {
  name: 'SignDetail',
  data() {
    return {
      orderNo: '',
      orderId: '',
      payCodeList: [],
      registration: {
        diyFormList: [],
        name: '',
        idserial: '',
        nextPayFlag: false
      },
      statusOptions: [
        { key: 'YES', value: '是' },
        { key: 'NO', value: '否' }
      ],
      btnNextLoading: false,
      diyFormShowFlag: false,
      diyFormList: [],
      diyRules: {},
      diyTemp: {
      },
      pickerList: {},
      projectName: '',
      temp: {
        projectName: '',
        engName: '',
        amtFlag: 'YES', // 固定金额是否
        authentication: 'YES',
        description: '',
        payamt: '',
        payAmount: 0,
        proModelUrl: '',
        rebateAmount: 0,
        billAmountStr: '',
        proTypeInfoId: null,
        imgFile: null,
        count: 1,
        sumAmount: 0,
        maxCount: 1,
        imgUrl: '',
        allowRebate: '',
        formAssemblyVoList: [],
        payUserAssemblyDataVO: [],
        payLimit: '',
        payLimitStr: '',
        actualPayAmount: 0,
        schoolCode: window.localStorage.getItem('schoolcode')
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getProjectInfo()
      this.userInfo()
      this.getUnpaidOrder()
    },
    changeId() {
      this.logout()
      this.$router.push({ path: 'signUpLogin' })
    },
    async logout() {
      await this.$store.dispatch('user/logout')
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 判断此项目批次是否存在未缴费订单
    getUnpaidOrder() {
      const selectProId = localStorage.getItem('selectProId')
      getUnpaidOrder(selectProId).then(response => {
        if (response.data === 0) { // 第一次缴费
        //   this.toCard2_vue() // 打开填报页
        } else {
          this.registration.nextPayFlag = true // 是否为继续支付订单标识
          //   this.$set(this.registration, 'diyFormList2', [])
          // 获取项目详情
          getProjectVoById(selectProId).then((response) => {
            this.registration.temp = response.data
          })
          // 获取项目详情，直接打开详情页面
          //   this.$forceUpdate()
          // 查看订单的支付方式是否为空 若为空可以选择支付方式，若不为空则只能选择之前的支付方式
          var nameListId = response.data.data
          getOrderInfoByNameListId(nameListId).then((response) => {
            console.log('getOrderInfoByNameListId')
            console.log(response.data)
            var tradeChannel = response.data[0].tradeChannel
            this.orderNo = response.data[0].orderNo
            if (tradeChannel !== null & tradeChannel !== undefined & tradeChannel !== '') {
              var obj = {}
              obj.paymentCode = tradeChannel
              obj.id = tradeChannel
              this.payCodeList = []
              this.payCodeList.push(obj)
            } else {
              this.getPayCodeListByProId(this.registration.temp.id)
            }
            // this.card3()
            // this.$forceUpdate()
            // 二维码隐藏
            // this.registration.showPayMode = true
          })
        }
      })
    },
    getPayCodeListByProId(proId) {
      getPayCodeListByProId(proId).then(response => {
        this.payCodeList = response.data
      })
    },
    onSubmit() {
      console.log('1111111111')
      this.handlerCreateOrder()
      //   this.$router.push({ path: 'person' })
    },
    userInfo() {
      this.registration.name = localStorage.getItem('name')
      this.registration.idserial = localStorage.getItem('idserial')
    },
    onConfirm(value) {
      console.log(value)
      this.diyTemp[this.pickerList.key] = value
      this.pickerList.showPicker = false
    },
    pickerClick(item) {
      this.pickerList = {}
      this.pickerList = item
      item.showPicker = true
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/signUpLogin' })
    },
    // 获取项目信息
    getProjectInfo() {
      const selectProId = localStorage.getItem('selectProId')
      getProjectVoById(selectProId).then(response => {
        this.projectName = response.data.projectName
        // 遍历 自定义表单内容
        this.temp = response.data
        console.log(response, 'res')
        this.temp.formAssemblyVoList.forEach(item => {
          var obj = {}
          obj.id = item.id
          obj.type = item.assemblyType
          obj.placeholder = item.tipsInfo
          obj.pros = item.mustStatus
          obj.label = item.showName
          obj.key = item.assemblyKey
          if (obj.type === 'input') {
            obj.comName = 'input'
            this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'blur' }])
          } else if (obj.type === 'select') {
            obj.showPicker = false
            obj.options = []
            this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'change' }])
            item.listAssemblyDetail.forEach(op => {
              var optionObj = {}
              optionObj.id = op.id
              optionObj.key = op.detailValue
              optionObj.label = op.showDetail
              optionObj.value = op.detailValue
              obj.options.push(optionObj)
            })
            obj.optionsDate = []
            obj.optionsDate = obj.options.map(v => v.label)
          } else if (obj.type === 'switch') {
            obj.comName = 'radio'
            this.$set(this.diyRules, obj.key, [{ required: true, message: '请选择', trigger: 'blur' }])
          }
          this.registration.diyFormList.push(obj)
        //   console.log(this.diyFormList, '123')
        })
      })
    },
    // update by yuxin
    handlerCreateOrder() {
      var createOrderVO = {}
      var payUserAssemblyDataVO = {}
      // var otherDataArray = []
      var otherData = {}
      // 自定义表单数组
      var formAssemblyVoList = []
      this.registration.diyFormList.forEach(item => {
        var payProjectFormAssembly = {}
        // 自定义表单元素赋值
        var payUserAssemblyDataVO = {}
        payProjectFormAssembly.id = item.id
        payUserAssemblyDataVO.id = item.id
        payProjectFormAssembly.assemblyType = item.type
        payProjectFormAssembly.schoolCode = window.localStorage.getItem('schoolcode')
        // payUserAssemblyDataVO.schoolCode = window.localStorage.getItem('schoolcode')
        payProjectFormAssembly.showName = item.label
        payProjectFormAssembly.assemblyKey = item.key
        payProjectFormAssembly.mustStatus = item.pros
        payProjectFormAssembly.tipsInfo = item.placeholder
        payProjectFormAssembly.formAssemblyValue = this.diyTemp[item.key]
        if (item.key === 'NAME') {
          payUserAssemblyDataVO.name = item.value
        } else if (item.key === 'SEX') {
          payUserAssemblyDataVO.sex = item.value
        } else if (item.key === 'IDSERIAL') {
          payUserAssemblyDataVO.idserial = item.value
        } else if (item.key === 'IDNUMBER') {
          payUserAssemblyDataVO.idNumber = item.value
        } else if (item.key === 'PHONE') {
          payUserAssemblyDataVO.phone = item.value
        } else if (item.key === 'EMAIL') {
          payUserAssemblyDataVO.email = item.value
        } else {
          // var obj = {}
          // obj.key = item.key
          // obj.value = this.temp[item.key]
          // otherDataArray.push(obj)
          this.$set(otherData, item.key, this.diyTemp[item.key])
        }
        formAssemblyVoList.push(payProjectFormAssembly)
      })
      // payUserAssemblyDataVO.otherData = JSON.stringify(otherDataArray)
      otherData = JSON.stringify(otherData)
      payUserAssemblyDataVO.otherData = otherData
      createOrderVO.payUserAssemblyDataVO = payUserAssemblyDataVO
      this.temp.formAssemblyVoList = formAssemblyVoList
      createOrderVO.payProjectVO = this.temp
      createRegistrationOrder(createOrderVO).then((response) => {
        console.log('cr', '111111111111')
        this.getPayCodeListByProId(this.temp.id)
        this.orderNo = response.data.orderNo
        this.orderId = response.data.id
        // this.registration.diyFormList2 = JSON.parse(JSON.stringify(this.registration.diyFormList))
        // this.registration.nextPayFlag = false
        // this.nextStep = true
        // this.nextLoading = false
        console.log('cr', '1.5')
        // 跳转
        this.$router.push({
          path: '/person',
          query: {
            'orderId': this.orderId,
            'projectId': localStorage.getItem('selectProId'),
            'type': 'sign'
          }})
        console.log('cr', '222')
      }).catch(() => {
        this.nextLoading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.red{
    color:red !important
}
.van-cell__value{
    line-height: 43px;
}
.red{
    .van-cell__value{
        color:red;
        span{
            color: red;
        }
    }
     .van-field__control--right{
        color:red
    }
}
.changId{
    color:#2F9FF2 !important;
    font-size: 14px;
    text-align:right;
}
#signDetail {
  min-height: 667px;
  position: relative;
  background-color: #f5f5f5;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
    padding: 10px;
  margin: 10px 15px 10px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  margin: 10px 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
// .lastbattry{
//   border-left: 4px #1989fa solid;
//   padding-left: 10px;
//   margin: 20px;
//   font-size: 16px;
//   font-weight: 500;
//   line-height: 16px;
//   span{
//     color: #1989fa;
//     float: right;
//   }
// }
.paymonry{
  color:#2E2E2E;
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  z-index: 99;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__title {
    color:#747474;
    span {
      height: 44px;
      line-height: 44px;
    }
}
.boxstyle .van-cell {
    padding: 18px 20px;
    color:#747474;
}
.boxstylemoney {
  .van-cell {
      padding: 18px 20px !important;
  }
}
</style>
<style lang="scss">
#signDetail{
    .van-field__control--right{
        color:red
    }
}
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
#signDetail .van-field__label {
    line-height: 24px !important;
    height: 24px !important;
}
#signDetail .van-field__value {
    line-height: 24px !important;
    height: 24px !important;
}
</style>
