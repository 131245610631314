import request from '@/utils/request'

export function getWaitingPayList(schoolCode, idserial, projectId) {
  return request({
    url: 'pay/pay/waitingPay/getWaitingPayList/' + schoolCode + '/' + idserial + '/' + projectId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getWaitingPayListBySchoolCode(schoolCode, idserial) {
  return request({
    url: 'pay/pay/waitingPay/getWaitingPayList/' + schoolCode + '/' + idserial,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function submitNextStep(data) {
  return request({
    url: 'pay/web/waitingPay/submitNextStep',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getPayCodeListByProId(projectid) {
  return request({
    url: 'pay/web/waitingPay/getPayCodeListByProId/' + projectid,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function toPayOrderTrade(data) {
  return request({
    url: 'pay/web/waitingPay/toPayOrderTrade',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

export function getPayType(projectId, enterType) {
  return request({
    url: 'pay/open/pay/payrouterref/queryTradeChannel/' + projectId + '/' + enterType,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getWaitingPayListForSanxinIcbc(schoolCode, idserial) {
  return request({
    url: 'pay/pay/waitingPay/getWaitingPayListForSanxinIcbc/' + schoolCode + '/' + idserial,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function sanxinIcbcSubmitNextStep(data) {
  return request({
    url: 'pay/web/waitingPay/sanxinIcbcSubmitNextStep',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function createStudentFeesOrder(data) {
  return request({
    url: 'pay/web/studentFees/createStudentFeesOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

